import { environment } from 'src/environments/environment';

export const ROUTES_CONFIG = {
  //  API BASE URL
  API_URL: GET_API_URL(),
  // LOCATION_API_URL: 'https:// r5xcdejffe.execute-api.ap-south-1.amazonaws.com/',
  LOCATION_API_URL: GET_API_URL(),
  //  Otp Config
  SEND_OTP: 'create_otp', //  POST
  RESEND_OTP: '',
  VERIFY_OTP: 'verify_otp', //  POST

  // reports
  GRAPH_REPORT: 'reports/dashboard', // get

  // dashboard
  DASHBORAD_PAGE: 'dashboard-page', // get
  //  User Config
  GET_USER_DETAIL: 'user', //  GET
  UPDATE_USER_DETAIL: 'user/:user_id', //  post
  USER_LOGOUT: 'user/logout', // POST

  //  Organization
  CREATE_ORGANIZATION: 'organisation', //  PUT
  EDIT_ORGANIZATION: 'organisation/:org_id', //  GET
  UPDATE_ORGANIZATION: 'organisation/:org_id', //  UPDATE
  ORG_SWITCH: 'organisation/switch', // POST

  //  Distributor
  GET_DISTRIBUTOR: 'distributors', //  GET
  SEARCH_USER: 'user/search', //  GET
  ADD_DISTRIBUTOR: 'distributors', //  PUT
  UPDATE_DISTRIBUTOR: 'distributors/:id',
  GET_DISTRIBUTOR_RETAILER_LIST: 'distributors/:distributor_id/retailers', //  GET

  //  Brand
  GET_BRAND: 'brands', //  GET
  ADD_BRAND: 'brands', //  GET
  UPDATE_BRAND: 'brands/:brand_id', //  GET

  //  Retailer
  GET_RETAILERS: 'retailers', //  GET
  GET_RETAILERS_SAVED: 'retailers/v1', //  GET
  EXPORT_RETAILERS: 'retailers/export', //  GET
  SAVE_RETAILER: 'retailers', //  PUT
  UPDATE_RETAILER: 'retailers/:retailer_id', //  UPDATE
  IMPORT_RETAILERS: 'retailers/import',
  FILTER_RETAILER: 'retailers/filters', //  GET
  RETAILER_INVITE: 'retailers/invite', // post
  ALL_RETAILER_INVITE: 'retailers/invite/all', // post
  ASSIGN_DISTRIBUTOR: 'retailers/assign', // post
  ADD_RETAILER_ORG: 'retailers/orgs', // put
  CREATE_RETAILER_GROUP: 'retailers/:org_id/retailer-groups', // POST
  UPDATE_RETAILER_GROUP: 'retailers/:org_id/retailer-groups/:group_id', // PUT
  FETCH_RETAILER_GROUP: 'retailers/:org_id/retailer-groups', // GET
  DELETE_RETAILER_GROUP: 'retailers/:org_id/retailer-groups/:group_id', // PUT
  ASSIGN_REMOVE_RETAILER_GROUP: 'retailers/retailer-groups/assignOrUnassign', // POST

  //  Products
  FETCH_PRODUCTS: 'products/fetch/:type', //  POST  <xls/json>
  IMPORT_PRODUCTS: 'products/import', //  POST
  AFTER_IMPORT_PRODUCTS: 'syncScheduler/inventoryUpdateAfterImport', //  POST
  DETAILS_PRODUCTS: 'products', //  GET
  UPDATE_DETAILS_PRODUCTS: 'products/combinations', //  POST
  UPDATE_PRODUCTS: 'products/:id', //  POST
  SAMPLE_EXCEL_DOWNLOAD: 'products/exceltemplate/:orgid', //  GET
  VALIDATE_EXCEL_HEADER: 'products/import/validate', //  POST
  PRODUCTS_FILTER: 'products/filters', // POST
  PRODUCT_ATTRIBUTES: 'products/attributes/sort', // GET
  PRODUCT_ADD: 'products', // put
  PRODUCT_TAGS_FETCH: 'products/tags', // POST
  PRODUCT_BARCODE_GENERATE: 'barcode/autogenrate', // POST
  INVOICE_PREFERENCES: 'products/invoice_pref', // POST
  PRODUCT_SIZES_GROUOP: ':org_id/products/size-groups', // GET
  CREATE_SIZE_GROUP: ':org_id/products/size-groups', //POST
  UPDATE_SIZE_GROUP: ':org_id/products/size-groups/:id', //PUT
  TAXES: 'taxes', // GET
  FETCH_COMBINATIONS_WISE: 'products/fetch/:type/:',

  // variation
  ADD_COMBINATION: 'products/:pid/combinations', // put


  //  Notification
  GET_NOTIFICATION: 'notifications',
  POST_READ_NOTIFICATION: 'notifications',
  ACTION_NOTIFICATION: 'notifications/:notification_id',

  //  Location Api
  COUNTRY_LIST: 'locations/getCountry', //  GET
  STATE_LIST: 'locations/getState', //  GET
  CITY_LIST: 'locations/getCity', //  GET

  //  Orders APIs
  ORDER_FILTER: 'orders/filters', // POST
  ORDER_LIST: 'orders/fetch/json',
  ORDER_LIST_SUBMIT: 'orders/submitted/fetch',
  ORDER_LIST_SAVED: 'orders/saved/fetch',
  ORDER_LIST_XLS: 'orders/fetch/xls', // POST
  ORDER_DETAILS: 'orders/:order_id/:type', // GET
  ORDER_UPDATE: 'orders/:order_id', // POST
  ORDER_DELETE: 'orders/:order_id', // DELETE
  ORDER_DETAILS_EXPORT: 'orders/:order_id/:type', // GET <pdf/excel>?view=REGULAR/CLASSIC
  ORDER_IMPORT: 'orders/import',
  ORDER_IMPORTLOGS: 'orders/importlog',
  ORDER_IMPORT_VALIDATE: 'orders/import/validate',
  SAMPLE_EXCEL_ORDER: 'orders/exceltemplate/:orgid', //  GET
  MULTIPLE_ORDERS_EXPORT: 'order-utilities/orders-report', //POST
  EXPORT_TRANSFER_TO_ERP: 'order-utilities/orders-report-classic', //POST
  ORDER_SETTINGS: 'orders/settings', // GET/POST

  SAVE_CART: 'orders/cart',
  CART_DETAILS: 'orders/:order_id/cart',
  CREATE_ORDER: 'orders',
  UPDATE_ORDER: 'orders/update/:orderID',
  ORDER_PRODUICT: 'orders/:orderID/products/', // GET
  ORDER_PRODUCT_DETAILS: 'orders/:order_id/productdetail', // GET product_id,brand_id
  ORDER_UPDATED_STATS: 'orders/:order_id/category/productdetail', // GET product_id,category_id,brand_id
  MARK_STATUS: 'orders/markstatus',
  ORDER_EXPORT_COLUMNS: 'orders/pdf-preference/:org_id/fetch', //GET
  ORDER_EXPORT_COLUMNS_UPDATE: 'orders/pdf-preference/:org_id/update', //PUT
  ORDER_UPDATE_LOGS: 'orders/:orderId/logs', //PUT
  ORDER_PASTE_TO_ALL: 'orders/:orderId/pasteToAll', //PUT

  ORDER_FILTER_SUBMIT: 'orders/filters/optimized', // 'reports/:pageType/filters',

  //  Teams APIs
  TEAM_LIST: 'team/:orgid', //  GET
  SAVE_USER: 'team/:orgid', // PUT
  UPDATE_USER: 'team/:userId', // POST

  GET_CATEGORIES: 'products/categories', // GET
  SAVE_CATEGORY: 'products/categories', // PUT
  UPDATE_CATEGORY: 'products/categories/:cat_id', // POST
  IMPORT_VALIDATE: 'products/import/validate', // post
  MARK_FEATURED: 'mark/featured', // POST <featured/unfeatured>
  MARK_UNFEATURED: 'mark/unfeatured', // POST <featured/unfeatured>
  ASSIGN_CATEGORY: 'products/categories/assign',
  FETCH_TAXES: 'taxes/:id', // get
  // Report
  REPORT_DISPATCH_FILTER: 'reports/dispatch/filters/', // post
  REPORT_DISPATCH_XLS: 'reports/dispatch/fetch/xls/', // post
  REPORT_PRODUCTION_FILTER: 'reports/production/filters', // post
  REPORT_PRODUCTION_XLS: 'reports/production/fetch/xls', // post

  // Transation Reports
  TRANSATION_LIST: ':org_id/transactions',

  // Payments
  FETCH_PAYMENT_DETAIL: ':org_id/get-payment-detail',
  ADD_PAYMENT: ':org_id/add-payment',

  // performance
  PERFORMANCE_FILTER: 'reports/:report_type/filters',
  PERFORMANCE_REPORT: 'reports/:report_type/fetch/json',
  PERFORMANCE_REPORT_EXPORT: 'reports/:report_type/fetch/xls',
  USER_DEVICE: 'user/device',

  // Challans
  CHALLANS: 'challans',
  CHALLAN_UPDATE_LOGS: 'challans/:challan_id/logs', //GET
  CHALLAN_VARIATION_REMOVE: 'challans/:challan_id/delete', //POST
  CHALLAN_DETAILS_EXPORT: 'challans/export-preferences/:org_id/fetch', // GET <pdf/excel>?view=REGULAR/CLASSIC
  CHALLAN_EXPORT_COLUMNS: 'challans/export-preferences/:org_id/fetch', // GET
  CHALLAN_EXPORT_COLUMNS_UPDATE: 'challans/export-preferences/:org_id/update', //PUT
  CHALLAN_EXPORT_FORMAT: 'challans/export/:format',

  // Invoices
  INVOICES: 'invoices',
  NEW_INVOICES: 'v1/invoices',
  GENERATE_INVOICE: 'invoices/generate',
  NEW_GENERATE_INVOICE: 'invoices/v1/generate',
  INVOICE_UPDATE_LOGS: 'invoices/:invoice_id/logs', //GET
  INVOICE_UPDATE: 'invoices/:invoice_id/update', //GET

  // Dispatch
  DISPATCH_UNFULFILLED: 'dispatch/fulfilled/:order_id',
  DISPATCH_CREATE: 'dispatch',
  DISPATCH_EXISTING: 'dispatch/fetch/existing',
  DISPATCH: 'dispatch/:dispatchId',
  DISPATCH_CANCEL: 'dispatch/:dispatchId/cancel',
  DISPATCH_FILTER: 'dispatch/filter/:filterType',
  DISPATCH_FILTER_SELECT: 'dispatch/:dispatchId/select',
  DISPATCH_DATA_FILTER: 'dispatch/:dispatchId/filter',
  DISPATCH_ORDER_EXISTS: 'dispatch/check-product-order-availability',
  DISPATCH_DETAILED_PREVIEW: 'dispatch/:dispatchId/detailed-preview/:type',

  FETCH_GENNERAL_SETTINGS: 'invoices/general/:org_id', // GET
  SAVE_GENNERAL_SETTINGS: 'invoices/general/:org_id', // POST

  STOCK_LOG: 'products/stock/:module_name/list', // 'products/stock/:comb_id/:type', // GET
  ADD_STOCK: 'products/stock/:module_name/add', // 'products/stock/:comb_id', // PUT
  TRANSFER_STOCK: 'products/stock/:module_name/transfer', // 'products/stock/transfer/:comb_id', // PUT

  // Invoice Preference
  INVOICE_PREFERENCE: 'invoices/preferences',


  // Inventory
  INVENTORY_FILTER: 'inventory/filters',
  INVENTORY_LISTING: 'inventory/fetch',
  INVENTORY_SETTING: 'inventory/settings',
  INVENTORY_IMPORT: 'inventory/import',
  INVENTORY_RESET: 'inventory/:moduleName/reset',
  INVENTORY_IMPORT_VALIDATE: 'inventory/import/validate',
  INVENTORY_TOTAL_VARIATION: 'inventory/totalVariations',
  SAMPLE_EXCEL_INVENTORY: 'inventory/exceltemplate/:orgid', //  GET

  BATCH_EXISTING: 'inventory/:moduleName/batches/fetch/existing',
  BATCH_CREATE: 'inventory/:moduleName/batches',
  BATCHES: 'inventory/:moduleName/batches/:type',
  BATCH_DETAIL: 'inventory/:moduleName/batches/:batchId',
  BATCH_DETAIL_UPDATE: 'inventory/:moduleName/batches/:batchId',
  BATCH_DETAIL_PRODUCT: 'inventory/batches/filter/products',
  BATCH_DETAIL_SELECT_PRODUCT: 'inventory/:moduleName/batches/:batchId/select',
  PRINT_BARCODES: 'download-barcodes/:batchId', //GET
  GET_CURRENT_COUNTRY: 'user/country',

  // Catalogues
  VERIFY_CATALOGUE_PAGE: 'products/catelogues/:code/verify_page',
  VERIFY_CATALOGUE_PIN: 'products/catelogues/:code/verify_pin',
  CREATE_CATALOGUE: 'products/catelogues',
  CATALOGUE_DETAILS: 'products/catelogues/:id',
  CATALOGUE_USER_VISIT: 'products/catelogues/:categlogId/user_visit',
  IMPORT_CATALOGUE_PRODUCTS: 'products/catelogues/:id/import', //
  EXPORT_CATALOGUE_PRODUCTS: 'products/catelogues/:id/export', //
  FETCH_CATALOGUE_PREFERENCE: 'products/catelogues/pdf-preference/:org_id/fetch', //
  UPDATE_CATALOGUE_PREFERENCE: 'products/catelogues/pdf-preference/:org_id/update', //
  CATALOGUE_LIST_FILTER: 'products/catelogues/filter',  // GET


  ORDER_REPORTS_FILTER: 'reports/order_report/filters', // 'reports/:pageType/filters',
  ORDER_REPORTS_FETCH: 'reports/:pageType/:type',
  ORDER_REPORTS_EXPORT: 'reports/:pageType_:type',

  CHALLAN_REPORTS_FILTER: 'challans/reports/:pageType/filters',
  CHALLAN_REPORTS_FETCH: 'challans/reports/:pageType/fetch/:type',

  // TRIPS
  TRIP_LIST: 'trips/json', // POST
  TRIP_UPDATE: 'trips/:id', // POST
  TRIP_ASSIGN: 'trips/:id/assign', // POST
  TRIP_DETAILS: 'trips/:id', // GET
  TRIP_CREATE: 'trips', // PUT
  TRIP_TOTAL_ORDERS: 'trips/:trip_id/totalOrders', // GET
  TRIP_CATALOGUE: 'trips/:trip_id/catalogue', // GET


  // MEETINGS
  MEETING_LIST: 'meetings/json', // POST
  MEETING_CITIES_LIST: 'meetings/cities', // POST
  MEETING_ASSIGN: 'meetings/:id/assign', // POST
  MEETING: 'meetings/:id', // POST
  MEETING_ADD: 'meetings', // PUT
  MEETING_TOTAL_ORDERS: 'meetings/:meeting_id/totalOrders', // GET
  MEETING_CATALOGUE: 'meetings/:meeting_id/catalogue', // GET

  SCHEDULES_FILTER: 'schedules/filter',
  SCHEDULES_TEAMS: 'teams/selection',

  // Schedule board
  SCHEDULE_TEAM: 'schedules/team', // POST
  SCHEDULE_JSON: 'schedules/json', // POST

  TRIPS_FILTER: 'trips/filter',
  MEETINGS_FILTER: 'meetings/filter',

  TICKETS_FETCH: 'support/tickets', // GET
  TICKETS_UPDATE: 'support/tickets/:ticketId', // POST

  // Image Gallery
  FETCH_ALL_IMAGES: 'allImages/:org_id/:type', // GET

  // Activity Logs
  GET_ACTIVITY_FEED: 'user/log',

  // Other Party
  GET_OTHER_PARTY: 'otherParties',

  // Consignee
  CONSIGNEE: ':org_id/consignees',

  // Grades
  GRADES: 'orders/:orderId/:categoryId/grade',
  GRADES_CATEGORY: 'gradesCategories',
  GRADES_VARIATIONS: 'orders/:orderId/:categoryId/gradeSizes'
};

/**
 * Get Api url according to host
 */
export function GET_API_URL() {
  const hostName = window.location.hostname;
  let API_URL = `${GET_API_ENDPOINT()}`;
  if (hostName === '127.0.0.1' || hostName === 'localhost') {
    // API_URL = 'http://localhost:5000/';
    API_URL = `${GET_API_ENDPOINT()}`;
  }
  return API_URL;
}

export function GET_API_ENDPOINT() {
  let apiSubPoint = 'api';
  if (environment.env === 'prod') {
    apiSubPoint = 'cmsapi'
  }
  return `https://${environment.env}.${apiSubPoint}.orderaxe.com/`;
}
