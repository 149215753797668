<div [ngSwitch]="data.type">
    <div *ngSwitchCase="'Handle401'">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm mr-3 proceed-btn failure"
                [mat-dialog-close]="true" cdkFocusInitial>
                Okay
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'delete'" style="z-index: 1000000;">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div md-dialog-actions class="text-right">
          <button class="btn btn-w-sm btn-outline-secondary mr-2" [matDialogClose]="null">Cancel</button>
          <button mat-raised-button color="warn" [matDialogClose]="true">Delete</button>
        </div>
    </div>
    <div *ngSwitchCase="'delete-2'">
      <div class="reject-dialog">
        <div md-dialog-title class="reject-title text-capitalize">
          {{ data.heading }}
        </div>
        <div md-dialog-content>
          <p class="rejected-text mb-0 text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
          <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div md-dialog-actions class="text-right">
          <button class="btn btn-w-sm btn-outline-secondary mr-2" [matDialogClose]="null">{{data.noButton || 'Cancel'}}</button>
          <button mat-raised-button color="warn" class="rject-btn failure" cdkFocusInitial [matDialogClose]="true">{{data.yesButton || 'Delete'}}</button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'delete-left'">
      <div class="reject-dialog">
        <div md-dialog-title class="reject-title">
          {{ data.heading }}
        </div>
        <div md-dialog-content>
          <p class="rejected-text mb-0 text-left" *ngIf="data.text" [innerHtml]="data.text"></p>
          <p class="note text-left" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div md-dialog-actions class="text-right">
          <button class="btn btn-w-sm btn-outline-secondary mr-2" [matDialogClose]="null">{{data.noButton || 'Cancel'}}</button>
          <button mat-raised-button color="warn" class="rject-btn failure" cdkFocusInitial [matDialogClose]="true">{{data.yesButton || 'Delete'}}</button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'danger-info'">
      <div class="reject-dialog">
        <div md-dialog-title class="reject-title">
          {{ data.heading }}
        </div>
        <div md-dialog-content>
          <p class="rejected-text mb-0 text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
          <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div md-dialog-actions class="text-right">
          <button class="btn btn-w-sm btn-outline-secondary mr-2" [matDialogClose]="null">{{data.noButton || 'Close'}}</button>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'TeamDeactivated'">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm mr-3 proceed-btn failure"
                [mat-dialog-close]="true" cdkFocusInitial>
                Okay
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'ConnectionError'">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm mr-3 proceed-btn failure"
                [mat-dialog-close]="true" cdkFocusInitial>
                Okay
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'Unsaved'" class="unsaved-modal">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure mt-0">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div class="unsaved-actions d-flex flex-wrap align-items-center justify-content-center">
            <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn leave"
                [mat-dialog-close]="true" cdkFocusInitial>
                Stay
            </button>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn failure"
                (click)="closeUnsaved('save')" cdkFocusInitial>
                Save & Proceed
            </button>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn leave"
                (click)="closeUnsaved('discard')" cdkFocusInitial>
                Discard
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'cartedProduct'">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure mt-0">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
            <button mat-button mat-raised-button color="accent" class="btn-w-sm mr-3 proceed-btn failure"
                (click)="closeCartedProduct('save')" cdkFocusInitial>
                Save & Proceed
            </button>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm mr-3 proceed-btn leave"
                [mat-dialog-close]="true" cdkFocusInitial>
                Stay
            </button>
        </div>
    </div>
    <div *ngSwitchCase="'CopyToAll'">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure mt-0">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
            <button class="btn btn-w-sm btn-outline-secondary mr-2" [mat-dialog-close]="false" cdkFocusInitial>
                Cancel
            </button>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn failure"
                [mat-dialog-close]="true" cdkFocusInitial>
                Yes, Copy All
            </button>
        </div>
    </div>

    <div *ngSwitchCase="'retailerAllInvite'">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure mt-0">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note info text-center " *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
            <button class="btn btn-w-sm btn-outline-secondary mr-2" [mat-dialog-close]="false" cdkFocusInitial>
                Cancel
            </button>
            <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn failure"
                [mat-dialog-close]="true" cdkFocusInitial>
                Yes, send
            </button>
        </div>
    </div>

    <div *ngSwitchCase="'InfoOnly'">
        <div mat-dialog-content>
            <h3 *ngIf="data.heading" class="failure mt-0">
                {{ data.heading }}
            </h3>
            <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div class="d-flex flex-wrap justify-content-center">
            <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn failure"
                [mat-dialog-close]="true" cdkFocusInitial>
                Okay
            </button>
        </div>
    </div>

    <div *ngSwitchCase="'exportConfirm'">
      <div>
        <h3 *ngIf="data.heading" class="failure mt-0 mb-2">
            {{ data.heading }}
        </h3>
        <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
        <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        <div class="d-flex flex-wrap justify-content-center">
          <button class="btn btn-w-sm btn-outline-secondary mr-2" [mat-dialog-close]="false">
            No
          </button>
          <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn failure"
            [mat-dialog-close]="true" cdkFocusInitial>
            Yes
          </button>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'completeConfirm'">
      <div>
        <h3 *ngIf="data.heading" class="text-primary mt-0 mb-2">
            {{ data.heading | uppercase }}
        </h3>
        <p class="paragraph text-center" *ngIf="data.text" [innerHtml]="data.text"></p>
        <p class="note text-center" *ngIf="data.note" [innerHtml]="data.note"></p>
        <div class="d-flex flex-wrap justify-content-center actions-button">
          <button class="btn btn-w-sm btn-outline-secondary mr-2" [mat-dialog-close]="false">
            No
          </button>
          <button mat-button mat-raised-button color="accent" class="btn-w-sm proceed-btn"
            [mat-dialog-close]="true" cdkFocusInitial>
            Yes
          </button>
        </div>
      </div>
    </div>

    <div *ngSwitchDefault>
        <div mat-dialog-content style="padding: 0px !important;">
            <p class="paragraph" *ngIf="data.text" [innerHtml]="data.text"></p>
            <p class="note" *ngIf="data.note" [innerHtml]="data.note"></p>
        </div>
        <div>
            <button mat-button mat-raised-button color="accent" class="float-right btn-w-sm proceed-btn"
                [mat-dialog-close]="true" cdkFocusInitial>
                Proceed
            </button>
            <button class="btn btn-w-sm btn-outline-secondary float-right mr-2" (click)="closeUnsaved('cancel')">
                Cancel
            </button>
        </div>
    </div>
</div>
